import React, {useLayoutEffect} from "react";
import {useNavigate} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DeliveryTradeBanner from '../components/DeliveryTradeBanner';
import {useProducts} from '../providers/ProductContext'
import {useCart} from '../hooks/UseCart';
import {VAT_RATE} from '../utils/Constants'

function App() {
    //todo loading etc
    const {products} = useProducts();
    const {cart, addToCart, removeFromCart, clearCart} = useCart(products);

    const navigate = useNavigate();

    const goToCheckout = () => {
        navigate('/checkout');
        window.scrollTo(0, 0);
    };

    const getProduct = (productId) => {
        return products.find(product => product.id === productId);
    }

    const itemsInBasket = cart.map(item => {
        return {...item, ...getProduct(item.id)}
    })
    const subTotal = +itemsInBasket.reduce((a, b) => a + (b.price || 0) * (b.quantity || 0), 0).toFixed(2);
    const vatAmount = +(subTotal * (VAT_RATE / 100)).toFixed(2);
    const totalPrice = +(subTotal + +vatAmount).toFixed(2);const totalQty = cart.reduce((acc, item) => acc + item.quantity, 0);

    const totals = {}
    products?.forEach(product => totals[`${product.id}`] = cart.filter(x => x.id === product.id).reduce((acc, item) => acc + item.quantity, 0))

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>
            <div className="min-h-screen px-6 lg:px-8">
                {/*<h1 className="p-8 text-2xl font-bold text-center">Dryhome Condensation online shop</h1>*/}
                {/*<h3 className="p-8 text-center">Manufacturers of the REMCON whole home ventilation system </h3>*/}

                {/*<div>*/}
                {/*    <img className="mx-auto max-w-xs sm:max-w-lg lg:max-w-2xl" src="in-situ-medium.jpg" alt=""/>*/}
                {/*</div>*/}
                {/*<ImageCarousel images={images} />*/}

                <DeliveryTradeBanner/>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
                    {products.length === 0 && <p>No products loaded yet...</p>}
                    {products?.map(product => (
                        <div key={`product-${product.id}`}>
                            {/*<h2 className="text-xl font-semibold mb-4">Products</h2>*/}
                            <div className="space-y-4">
                                {/*{products.map((product) => (*/}
                                <div key={`product-${product.id}`} className="bg-white p-4 rounded-lg shadow">
                                    <h3 className="text-lg font-bold">{product.description}</h3>
                                    <img src={product.image} className="max-w-64 mx-auto"
                                         alt={`${product.name} main `}/>
                                    <p>£{product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} plus VAT</p>
                                    {totals[product.id] > 0 ? <div>
                                        <button
                                            className="bg-gray-200 text-gray-800 px-3 py-1 rounded hover:bg-gray-300 inline ml-1" // Added a small left margin
                                            onClick={() => removeFromCart(product.id)}
                                        >
                                            -
                                        </button>
                                        <span className={"px-2"}>{totals[product.id]}</span>
                                        <button
                                            className="bg-gray-800 text-white px-3 py-1 rounded hover:bg-gray-900 inline ml-1" // Added a small left margin for spacing
                                            onClick={() => addToCart(product.id)}
                                        >
                                            +
                                        </button>
                                    </div> : <button
                                        onClick={() => addToCart(product.id)}
                                        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
                                    >
                                        Add to Cart
                                    </button>}


                                </div>
                                {/*))}*/}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/*space for when the flyout is up*/}
            {totalQty > 0 && products.length > 0 && <div className="py-48 "></div>}
            <Footer/>
            <div
                className={`rounded-t-lg fixed bottom-0 left-0 right-0 bg-slate-400 p-4 shadow-lg transform transition-transform duration-300 ease-in-out ${totalQty > 0 && products.length > 0 ? "translate-y-0" : "translate-y-full"}`}
                id="flyout-panel">
                <p className={"mb-1"}><b>Your Basket</b> </p>
                {itemsInBasket.map(item => (
                    <div key={item.id} className="flex items-center mt-2"> {/* Flex container for item */}
                        <p className="flex-1"> {/* No margin to keep text close to buttons */}
                            {item.quantity} x {item.shortName}
                            <button
                                className="bg-gray-200 text-gray-800 px-3 py-1 rounded hover:bg-gray-300 inline ml-1" // Added a small left margin
                                onClick={() => removeFromCart(item.id)}
                            >
                                -
                            </button>
                            <button
                                className="bg-gray-800 text-white px-3 py-1 rounded hover:bg-gray-900 inline ml-1" // Added a small left margin for spacing
                                onClick={() => addToCart(item.id)}
                            >
                                +
                            </button>
                        </p>
                    </div>
                ))}


                <div className="w-full max-w-sm pl-4 pt-2 rounded-lg">
                    <div className="grid grid-cols-2 ">
                        <span className="text-gray-700">Subtotal:</span>
                        <span className="text-gray-900 font-semibold text-left">£{subTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>

                    <div className="grid grid-cols-2 pt-1">
                        <span className="text-gray-700">VAT (20%):</span>
                        <span className="text-gray-900 font-semibold text-left">£{vatAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>

                    <div className="grid grid-cols-2 mt-2">
                        <span className="text-black font-extrabold">Total:</span>
                        <span className="text-black font-extrabold text-left ">£{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                </div>
                <button className="mt-3 ml-3 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        onClick={() => goToCheckout()}>Checkout
                </button>

                <btn  onClick={clearCart} className="pl-4 font-medium text-blue-600 dark:text-blue-600 hover:underline cursor-pointer">Empty Basket</btn>
            </div>
        </div>
    );
}

export default App;
