import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Info() {
    return (
        <div className="bg-white relative pt-20">
            <Header cart={[]}/>
            <div className="min-h-screen px-6 pt-6 lg:px-8">
                <div>
                    <p>Dryhome Website Version {process.env.REACT_APP_VERSION}</p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Info;
