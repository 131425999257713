import React, {useLayoutEffect} from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {useProducts} from '../../providers/ProductContext'
import {useCart} from '../../hooks/UseCart';

function Landlords() {
    const {products} = useProducts();
    const {cart} = useCart(products);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>

            <section className="py-12 bg-white">
                <div className="container mx-auto px-10 pb-6 space-y-6">

                    <h2 className="text-3xl font-bold text-blue-900 text-center mb-6">Landlords</h2>
                    <p className="text-l text-blue-900 text-center mb-6">How many times have you attended properties to
                        conduct a survey and found that the symptoms the customer’s property is exhibiting are in fact
                        caused by condensation rather than damp?</p>
                </div>
                <section className="bg-blue-50 py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left">
                            <h3 className="text-2xl font-bold text-blue-900 mb-4">Condensation
                                Solutions for Landlords</h3>
                            <p className="text-gray-700 py-4">Whether you are a local authority or a private landlord,
                                condensation and the mould associated can be a major headache. The internet has enabled
                                tenants to have a greater understanding of the health risks associated with mould
                                spores.</p>

                            <p className="text-gray-700 py-4">This can lead to poor landlord / tenant relations with
                                disputes over responsibility for condensation problems. Unlike owner-occupiers,
                                landlords are not in control of the day to day conditions within their rental property.
                            </p>
                        </div>

                        <img src="./landlord.jpg" alt="trade use"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>

                    </div>
                </section>

                <section className="bg-white py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row-reverse items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="container mx-auto px-6 py-12 flex justify-center">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

                                <div className="flex items-start space-x-4">
                                    <div className="text-blue-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor"
                                             stroke-width="4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M5 13l4 4L19 7"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-semibold text-blue-900">Tenants typically do not
                                            ventilate</h3>
                                        <p className="text-gray-700">Unfortunately in most cases in rental
                                            properties it is the lifestyle of the tenants which determines whether
                                            condensation and black spot mould become a problem. You can of course
                                            try to educate the tenant regarding sufficiently ventilating the
                                            property, particularly the kitchen and bathroom areas, as well as
                                            avoiding using radiators to dry clothes and also to use the heating
                                            system correctly. However experience will tell you that tenants will
                                            still continue with their bad habits.</p>
                                    </div>
                                </div>
                                <div className="flex items-start space-x-4">
                                    <div className="text-blue-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor"
                                             stroke-width="4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M5 13l4 4L19 7"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-semibold text-blue-900">CONTROLS</h3>
                                        <p className="text-gray-700">With the new REMCON you have an effective
                                            solution to all your condensation problems whatever the size of the
                                            property. The 99 speed setting inra red remote control allows fine
                                            tuning of the air input to suit the size of the property, but the
                                            tenants are unable to turn it off. The REMCON is competitively priced
                                            and virtually maintenance free. It is very economical to run and simple
                                            to install.</p>
                                    </div>
                                </div>
                                <div className="flex items-start space-x-4">
                                    <div className="text-blue-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor"
                                             stroke-width="4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M5 13l4 4L19 7"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-semibold text-blue-900">EASY INSTALLATION</h3>
                                        <p className="text-gray-700">The REMCON units are simple to install.
                                            Installation time for the REMCON is approximately 45 minutes. All units
                                            are supplied with comprehensive fitting instructions. If you have any
                                            installation enquiries or would like more detailed information, please
                                            contact us.</p>
                                    </div>
                                </div>
                                <div className="flex items-start space-x-4">
                                    <div className="text-blue-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor"
                                             stroke-width="4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M5 13l4 4L19 7"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-semibold text-blue-900">GUARANTEE</h3>
                                        <p className="text-gray-700">For peace of mind we offer a five year
                                            warranty. It is a fit and forget system that really cures the symptoms
                                            of condensation. Please have a look at our testimonials page where you
                                            will get an idea of how the REMCON has cured customer's condensation
                                            problems. In our FAQ's section you will also find information on the
                                            causes of condensation and how positive pressure systems work.</p>
                                    </div>
                                </div>
                                <div className="flex items-start space-x-4">
                                    <div className="text-blue-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor"
                                             stroke-width="4">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M5 13l4 4L19 7"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="text-lg font-semibold text-blue-900">BUILDING
                                            REGULATIONS</h3>
                                        <p className="text-gray-700">The REMCON fully complies with Part F
                                            (Ventilation) 2010 Building Regulations.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

            </section>


            <Footer/>
        </div>
    );
}

export default Landlords;
