import React from "react";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div>
            <footer className="bg-slate-800 text-white py-10">
                <div className="container mx-auto px-6 lg:px-12 max-w-6xl">
                    <div className="flex flex-col lg:flex-row justify-between items-center space-y-8 lg:space-y-0 lg:space-x-10">
                         {/*Slogan and Contact Details */}
                        <div className="text-center lg:text-left space-y-2">
                            <h3 className="text-lg font-semibold text-slate-100">Dryhome Condensation</h3>
                            <p className="text-sm text-slate-300">Take control of Condensation. We won't sell a system unless it is the right solution for you.</p>
                            <div className="text-sm text-slate-300">
                                <p>
                                    <a href="tel:+01269841314" className="hover:text-blue-400">Phone: 01269 841314</a>
                                </p>
                                <p>
                                    <a href="tel:+07971433030" className="hover:text-blue-400">Mobile: 07971 433030</a>
                                </p>
                                <p>
                                    <a href="mailto:info@dryhomecondensation.co.uk" className="hover:text-blue-400">Email: info@dryhomecondensation.co.uk</a>
                                </p>
                            </div>
                        </div>

                        {/* Navigation Links */}
                        <div className="text-center lg:text-right space-y-2">
                            <h3 className="text-lg font-semibold text-slate-100">Quick Links</h3>
                            <nav className="space-y-1">
                                <Link to="/" className="block text-slate-300 text-sm hover:text-blue-400">Home</Link>
                                <Link to="/remcon" className="block text-slate-300 text-sm hover:text-blue-400">The REMCON</Link>
                                <Link to="/faq" className="block text-slate-300 text-sm hover:text-blue-400">FAQ</Link>
                                <Link to="/warranty" className="block text-slate-300 text-sm hover:text-blue-400">Warranty</Link>
                                <Link to="https://pay.dryhome.co" className="block text-slate-300 text-sm hover:text-blue-400">Pay an Invoice</Link>
                                <Link to="/contact" className="block text-slate-300 text-sm hover:text-blue-400">Contact Us</Link>
                            </nav>
                        </div>
                    </div>

                    {/* Footer Bottom */}
                    <div className="mt-10 text-center text-slate-400 text-xs">
                        &copy; {new Date().getFullYear()} Dryhome Condensation. All rights reserved.
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
