import React, { useState, useEffect, useRef, useCallback } from 'react';

const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);

    // Memoize goToNextSlide with useCallback to prevent it from changing on each render
    const goToNextSlide = useCallback(() => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    }, [images.length]);

    const goToPrevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    // Set up the auto-slide effect
    useEffect(() => {
        const interval = setInterval(goToNextSlide, 5000); // Slide every 5 seconds
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [goToNextSlide]);

    useEffect(() => {
        const handleResize = () => {
            if (carouselRef.current) {
                const slideWidth = carouselRef.current.clientWidth;
                carouselRef.current.style.transform = `translateX(-${
                    currentIndex * slideWidth
                }px)`;
            }
        };
        handleResize(); // Adjust on load
        window.addEventListener('resize', handleResize); // Adjust on resize

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [currentIndex]);

    return (
        <div className="relative w-full max-w-xl mx-auto overflow-hidden h-100">
            {/* Carousel Images Wrapper */}
            <div
                ref={carouselRef}
                className="flex transition-transform duration-700 ease-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        // todo give this a proper name
                        alt={`Slide ${index + 1}`}
                        className="w-full h-100 object-cover flex-shrink-0"
                    />
                ))}
            </div>

            {/* Navigation Buttons with Arrow Icons */}
            <button
                onClick={goToPrevSlide}
                className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 opacity-50 hover:opacity-100"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <button
                onClick={goToNextSlide}
                className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 opacity-50 hover:opacity-100"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
            </button>

            {/* Pagination Dots */}
            <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`h-2 w-2 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default ImageCarousel;
