import React, {useLayoutEffect} from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import {useProducts} from '../providers/ProductContext'
import {useCart} from '../hooks/UseCart';
import Gallery from "../components/Gallery";
import {Link} from 'react-router-dom';

function Remcon() {
    const {products} = useProducts();
    const {cart} = useCart(products);

    useLayoutEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) element.scrollIntoView({ behavior: 'auto' });
        } else {
            window.scrollTo(0, 0)
        }
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>


            <section className="py-12 bg-white">
                <h2 className="text-3xl font-bold text-blue-900 text-center mb-6">The REMCON</h2>
                <h3 className="text-xl font-semibold text-blue-900 text-center mb-6">The remote control whole home ventilation system</h3>

                <p className="text-l text-blue-900 text-center mb-6">The most effective method of condensation control for domestic properties.</p>

                <div className="container mx-auto px-6 py-12 flex justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">EASILY INSTALLLED</h3>
                                <p className="text-gray-700">in houses and bungalows</p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">CONTROLS</h3>
                                <p className="text-gray-700">Condensation, Mildew, Mould & Dampness</p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">99 SPEED SETTINGS</h3>
                                <p className="text-gray-700">Remote Control</p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">LOW MAINTENANCE</h3>
                                <p className="text-gray-700">Low Cost filter maintenance annually</p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">HEALTHIER</h3>
                                <p className="text-gray-700">Provides a healthier way of living</p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">ENERGY EFFICIENT</h3>
                                <p className="text-gray-700">Low Running Costs, better utilisation of existing heating</p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">5 YEAR WARRANTY</h3>
                                <p className="text-gray-700">Peace of mind with a 5 year manufacturers warranty</p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">WHISPER QUIET</h3>
                                <p className="text-gray-700">When in operation</p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">PATENTED DESIGN</h3>
                                <p className="text-gray-700"></p>
                            </div>
                        </div>

                        <div className="flex items-start space-x-4">
                            <div className="text-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor"
                                     stroke-width="4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-blue-900">FULLY COMPLIES</h3>
                                <p className="text-gray-700">With 2010 Building Regulations</p>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="flex items-center justify-center min-h-screen bg-gray-100">
                    <img
                        src="./in-situ-closeup.jpg"
                        alt="The REMCON in place"
                        className="w-full max-w-lg rounded-lg shadow-lg object-cover"
                    />
                </div>

                <section className="bg-blue-50 py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left">
                            <h2 className="text-4xl font-bold text-blue-900 mb-8">
                                TAKE CONTROL OF CONDENSATION
                            </h2>

                            <p className="text-lg text-gray-700 mb-6">
                                Windows dripping condensation, water pooling on sills, damp curtains, black mould growth
                                on walls and ceilings, mildew on clothing, musty smells... Welcome to a typical UK home
                                in winter.
                            </p>

                            <p className="text-lg text-gray-700 mb-10">
                                The REMCON whole home ventilation system discreetly banishes condensation from homes.
                            </p>

                            <p className="text-lg text-gray-700 mb-10">
                                The system is installed in the roof space of the home, with an unobtrusive ceiling
                                louvre that fits seamlessly with any home decor.
                            </p>

                            <div className="text-left">
                                <ul className="space-y-6">

                                    <li className="flex items-start space-x-4">
                                        <div className="text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="3">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-blue-900">CONTROLS</h3>
                                            <p className="text-gray-700">Condensation, mildew, mould, and dampness.</p>
                                        </div>
                                    </li>

                                    <li className="flex items-start space-x-4">
                                        <div className="text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="3">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-blue-900">HEALTH</h3>
                                            <p className="text-gray-700">Creates a fresher, cleaner living environment
                                                for you and your family.</p>
                                        </div>
                                    </li>

                                    <li className="flex items-start space-x-4">
                                        <div className="text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="3">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-blue-900">ENERGY EFFICIENT</h3>
                                            <p className="text-gray-700">Low running costs, better utilisation of
                                                existing heating.</p>
                                        </div>
                                    </li>

                                    <li className="flex items-start space-x-4">
                                        <div className="text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="3">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-blue-900">SECURITY</h3>
                                            <p className="text-gray-700">Extends the life of curtains, carpets, and
                                                paintwork.</p>
                                        </div>
                                    </li>

                                    <li className="flex items-start space-x-4">
                                        <div className="text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="3">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-blue-900">SAVES TIME</h3>
                                            <p className="text-gray-700">Banishes morning windowsill mopping up.</p>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div className="lg:w-1/2">
                            <div className="container mx-auto px-4 py-8">
                                <div className="grid grid-cols-2 gap-4">
                                    <img src="./window-condensation.jpg" alt="Mouldy Window 1"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                    <img src="./window-condensation-mould-hand.jpg" alt="Mouldy Window 2"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                    <img src="./window-mould.jpg" alt="Mouldy Window 3"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                    <img src="./condensation-mould-cleaner.jpg" alt="Mouldy Window 4"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                    <img src="./wet-window-sponge.jpg" alt="Mouldy Window 4"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                    <img src="./condensation-mould-cleaning.jpg" alt="Mouldy Window 4"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="bg-white py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row-reverse items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left ml-8">
                            <h2 className="text-4xl font-bold text-blue-900 mb-8">
                                PEACE OF MIND
                            </h2>

                            <p className="text-lg text-gray-700 mb-6">
                               The REMCON is whisper quiet, needs minimal maintenance, has a proven track record and is guaranteed.
                            </p>

                            <p className="text-lg text-gray-700 mb-10">
                                The REMCON is a true ventilation system that uses nature's fresh air to ventilate your entire home.
                            </p>

                            <p className="text-lg text-gray-700 mb-10">
                                A ventilation system installed unobtrusively in the roof space of your property, gently introducing fresh, filtered ait into the home.
                            </p>

                        </div>

                        <div className="lg:w-1/2">
                            <img
                                src="./remcon-unit-with-remote-small.jpg"
                                alt="REMCON unit with remote"
                                className="w-full rounded-lg shadow-lg object-cover"
                            />
                        </div>

                    </div>
                </section>

                <section className="bg-gray-100 py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left">
                            <h2 className="text-4xl font-bold text-blue-900 mb-8">
                                THE DIFFERENCE IS CLEAR
                            </h2>

                            <p className="text-lg text-gray-700 mb-6">
                                This fresh air replaces the moist, damp air created by everyday living in the home. Unlike a conventional
                                dehumidifier the REMCON is quiet, requires minimal maintenance, does not require daily emptying and is
                                concealed in the attic.
                            </p>

                            <p className="text-lg text-gray-700 mb-6">
                                All you see is a slim, unobtrusive louvre, not a large piece of machinery that takes up floor space and has to be moved around
                            </p>

                            <p className="text-lg text-gray-700 mb-6">
                                The system ventilates the home from within, thereby eliminating the need to open windows.
                            </p>

                            <p className="text-lg text-gray-700 mb-6">
                                REMCON customers can regulate the level of ventilation required throughout the home via a new 99 speed infra-red remote control.
                            </p>

                            <p className="text-lg text-gray-700 mb-6">
                                These advances allow home owners to choose the desired amount of ventilation required, thereby creating a cleaner, fresher home.
                            </p>


                        </div>

                        <div className="lg:w-1/2">
                            <img
                                src="./remcon-unit-filter-remote.jpg"
                                alt="The REMCON components"
                                className="w-full max-w-lg rounded-lg shadow-lg object-cover"
                            />
                        </div>

                    </div>
                </section>

                <div className="container mx-auto px-6 py-6 space-y-12" id={"settings"}>
                    <div className="flex flex-col md:flex-row items-center md:space-x-6 space-y-6 md:space-y-0">
                        {/*<img src="https://via.placeholder.com/400x300" alt="Domestic use"*/}
                        {/*todo redo this diagram its awful*/}
                        <img src="./remcon-graphic.png" alt="Domestic use"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>
                            <div className="text-center md:text-left md:w-1/2">
                                <h3 className="text-2xl font-bold text-blue-900 my-4">REMCON Unit Settings</h3>
                                <p className="text-gray-700">
                                    The REMCON unit is CE marked and fully complies with Part F of the 2010 Building Regulations. Below is a table which will explain what the recommended air flow rates are, for properties ranging in size from 1 to 5 bedrooms
                                </p>

                                <div className="overflow-x-auto my-5">
                                    <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
                                        <thead>
                                        <tr className="bg-blue-100 text-blue-900 text-left font-semibold">
                                            <th className="px-4 py-3 border-b border-gray-200">Number of Bedrooms</th>
                                            <th className="px-4 py-3 border-b border-gray-200">Approx. suggested setting</th>
                                            <th className="px-4 py-3 border-b border-gray-200">Litres per second</th>
                                            <th className="px-4 py-3 border-b border-gray-200">RPM</th>
                                        </tr>
                                        </thead>
                                        <tbody className="text-gray-700">
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">1</td>
                                            <td className="px-4 py-3 border-b border-gray-200">10</td>
                                            <td className="px-4 py-3 border-b border-gray-200">12</td>
                                            <td className="px-4 py-3 border-b border-gray-200">500</td>
                                        </tr>
                                        <tr className="bg-gray-50">
                                            <td className="px-4 py-3 border-b border-gray-200">2</td>
                                            <td className="px-4 py-3 border-b border-gray-200">20</td>
                                            <td className="px-4 py-3 border-b border-gray-200">16</td>
                                            <td className="px-4 py-3 border-b border-gray-200">600</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">3</td>
                                            <td className="px-4 py-3 border-b border-gray-200">30</td>
                                            <td className="px-4 py-3 border-b border-gray-200">20</td>
                                            <td className="px-4 py-3 border-b border-gray-200">700</td>
                                        </tr>
                                        <tr className="bg-gray-50">
                                            <td className="px-4 py-3 border-b border-gray-200">4</td>
                                            <td className="px-4 py-3 border-b border-gray-200">40</td>
                                            <td className="px-4 py-3 border-b border-gray-200">24</td>
                                            <td className="px-4 py-3 border-b border-gray-200">800</td>
                                        </tr>
                                        <tr >
                                            <td className="px-4 py-3 border-b border-gray-200">5</td>
                                            <td className="px-4 py-3 border-b border-gray-200">50</td>
                                            <td className="px-4 py-3 border-b border-gray-200">28</td>
                                            <td className="px-4 py-3 border-b border-gray-200">900</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <p className="text-gray-700">
                                    The unit is factory preset on 30, which is the suggested setting for a three bed property. The settings are approximate because the beauty of the REMCON unit is that with 99 settings it can be fine tuned to suit each individual property.
                                </p>
                            </div>
                    </div>
                </div>

                    <section className="bg-blue-50 py-16 px-6" id={"technical"}>
                        <div className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10 bg-blue-50">
                            <div className="text-center md:text-left md:w-1/2">
                                <h3 className="text-2xl font-bold text-blue-900 my-4">REMCON Technical Information</h3>
                                <p className="text-gray-700">Some technical specifications are listed below. Feel free to Contact Us for any other information you may need</p>

                                <div className="overflow-x-auto my-5">
                                    <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
                                        <tbody className="text-gray-700">
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Number of Settings</td>
                                            <td className="px-4 py-3 border-b border-gray-200">99</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Voltage</td>
                                            <td className="px-4 py-3 border-b border-gray-200">240V</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Power</td>
                                            <td className="px-4 py-3 border-b border-gray-200">22 Watts</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Sound Level</td>
                                            <td className="px-4 py-3 border-b border-gray-200">40 dBA</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Dimensions</td>
                                            <td className="px-4 py-3 border-b border-gray-200">Approx 33L x 33W x 30H (cm) (see diagram opposite for full details)</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Net Weight</td>
                                            <td className="px-4 py-3 border-b border-gray-200">3 Kg</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Colour</td>
                                            <td className="px-4 py-3 border-b border-gray-200">White</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Filter Grade</td>
                                            <td className="px-4 py-3 border-b border-gray-200">G4</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Remote Control Battery (included)</td>
                                            <td className="px-4 py-3 border-b border-gray-200">12V 23A / A23</td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-3 border-b border-gray-200">Package Contents</td>
                                            <td className="px-4 py-3 border-b border-gray-200">1 Remcon, 1 Remote, 1 Filter, 1 Fixing Kit, 1 Installation Template, Full Installation Instructions</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <p className="text-gray-700 pt-3"><b>Electrical information</b> The unit can be wired in one of two ways:
                                    <ul className={"list-decimal px-8 py-2"}>
                                        <li>
                                            By spurring off the existing lighting circuit via a 5-amp fused spur. The lighting circuit must be earthed and the unit must be wired by a qualified electrician.
                                        </li>
                                        <li>
                                            By plugging into an existing attic socket via a 5-amp fused plug.
                                        </li>
                                    </ul></p>
                                <p className="text-gray-700 px-8 pb-6">Note: One 5-amp fuse is enclosed in the fixing kit.</p>
                            </div>
                            <img src="./remcon-dimensions.png" alt="Remcon Dimensions"
                                 className="w-full md:w-1/2 rounded-lg shadow-md"/>
                        </div>
                </section>

                <section className="bg-white py-16 px-6" id={"downloads"}>
                    <div className="container mx-auto items-center space-y-8 lg:space-y-0 lg:space-x-10">
                        <div className="text-left ">
                            <h3 className="text-2xl font-bold text-blue-900 my-4">Downloads</h3>
                            <p className="text-gray-700">Useful Downloads can be found below. Feel free to Contact Us for any other information you may need</p>

                            <ul className="list-none pt-3">
                                <li><a href="/files/remcon-fitting-instructions-1-person.pdf" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">Installation Instructions (1 person)</a></li>
                                <li><a href="/files/remcon-fitting-instructions-2-person.pdf" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">Installation Instructions (2 person)</a></li>
                                <li><a href="/files/remcon-user-manual-faq.pdf" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">REMCON User Manual</a></li>
                                <li><a href="/files/remcon-literature.pdf" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">REMCON Product Literature</a></li>
                                <li><a href="/files/remcon-dimensions.pdf" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">REMCON Dimensions</a></li>
                                <li><a href="/files/remcon-infographic.pdf" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">REMCON Infographic</a></li>
                            </ul>
                        </div>
                    </div>
                </section>

                <Gallery/>

            </section>

            <div className="bg-gray-100 py-8 px-4 text-center">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    What Now?
                </h2>
                <p className="text-gray-600 mb-6">
                    Read our FAQs, Shop Now, or Contact us to hear about our discounted trade prices.
                </p>
                <div className="flex justify-center space-x-4">
                    <Link
                        to="/faq"
                        className="bg-gray-800 text-white py-2 px-6 rounded-lg shadow hover:bg-gray-700 transition duration-300"
                    >
                        FAQs
                    </Link>
                    <Link
                        to="/shop"
                        className="bg-indigo-600 text-white py-2 px-6 rounded-lg shadow hover:bg-indigo-500 transition duration-300"
                    >
                        Shop Now
                    </Link>
                    <Link
                        to="/contact"
                        className="bg-teal-500 text-white py-2 px-6 rounded-lg shadow hover:bg-teal-400 transition duration-300"
                    >
                        Contact Us
                    </Link>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default Remcon;
