import React, {useLayoutEffect} from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import {useProducts} from '../providers/ProductContext'
import {useCart} from '../hooks/UseCart';

function FAQ() {
    const {products} = useProducts();
    const {cart} = useCart(products);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>

            <section className="bg-gray-100 py-16">
                <div className="container mx-auto px-10 pb-6 space-y-6">

                    <h2 className="text-4xl font-bold text-center text-blue-900 mb-12">Frequently Asked Questions</h2>
                    <p className="text-l text-blue-900 text-center mb-6">REMCON® is whisper quiet, needs minimal
                        maintenance, has a proven
                        track
                        record and is guaranteed. It is a true ventilation system that uses nature's fresh air to
                        ventilate your entire house.</p>

                </div>
                <div className="container mx-auto px-6 lg:px-12 max-w-6xl">

                    <div className="space-y-8">

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">What causes Condensation?</h3>
                            <p className="text-gray-600">Condensation occurs when moisture laden air comes into contact with a colder surface. Warm air is able to hold more moisture than cool air and as it cools the moist air releases water vapour as condensation.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">How is a REMCON different to a Dehumidifier?</h3>
                            <p className="text-gray-600">The REMCON system is highly effective, cheap to run, whisper quiet and ventilates the home from within with fresh air. It is virtually maintenance free and does not require the daily emptying of containers. Only one unit is required per home
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">How does it work?</h3>
                            <p className="text-gray-600">The REMCON is positive pressure system. The REMCON draws air of lower humidity from outside into the roof space, where it warms. This drier air is introduced into the living space and lowers humidity by forcing moist air out of the property via gaps in doors, windows and air bricks, replacing the air with drier fresh air.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">What are the effects of Condensation to family health and the home</h3>
                            <p className="text-gray-600 mb-2">Black mould spores have been identified as the source of many health problems. These spores produce allergens and irritants which can worsen the symptoms of asthma, allergies and sinusitis.</p>
                            <p className="text-gray-600 mb-2">The REMCON ventilates the home from within thereby removing the need to open windows. It also saves time removing the need for wiping window sills. It also gives curtains, carpets and paintwork a longer life.</p>
                            <p className="text-gray-600">The REMCON thereby creates a cleaner, fresher living environment for you and your family.</p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Can I fit
                                them myself?</h3>
                            <p className="text-gray-600">Yes, if there is a plug socket in the loft space anyone with basic DIY skills with find the REMCON easy to install. If the property does not have a plug socket in the attic, then the unit needs to be spurred of the lighting circuit, by a qualified electrician.</p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Controls?</h3>
                            <p className="text-gray-600 mb-2">In order to solve the problem of consideration, the unit inputs fresh air into the property. With the new REMCON, the air input can be fine-tuned via the 99 speed infra red remote control to suit the size of the property.</p>
                            <p className="text-gray-600">In the event of a power cut, the unit will restart at the setting it was on, prior to the power cut.</p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Do I have to do anything else for the REMCON to cure the problem?</h3>
                            <p className="text-gray-600">We recommend that internal doors in affected rooms are left slightly ajar to allow the circulation of fresh air form the REMCON unit. Also ensure that the roof-space is adequately ventilated. Allow the unit to run for approximately one week after installation, before one initial cleaning of the black spot mould using a proprietary mould killer or antibacterial liquid. Take the appropriate safety precautions as recommended by the cleaning product manufacturer e.g. wear rubber gloves, safety goggles, mask etc.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">What maintenance is required?</h3>
                            <p className="text-gray-600 mb-2">We recommend that the filter is changed annually. Filters are low cost and available to purchase from us. Replacing a filter is a very quick, 5 minute job and is the best way to prolong the life of your unit. </p>
                            <p className="text-gray-600">Other than this, the REMCON is maintenance free.</p>
                        </div>


                    </div>
                </div>
            </section>


            <Footer/>
        </div>
    );
}

export default FAQ;
