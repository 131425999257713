import React, {useLayoutEffect} from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {useProducts} from '../../providers/ProductContext'
import {useCart} from '../../hooks/UseCart';

function Contact() {
    const {products} = useProducts();
    const {cart} = useCart(products);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>

            <section className="bg-gray-50 py-16">
                <div className="container mx-auto px-6 lg:px-12 max-w-6xl">
                    <h2 className="text-4xl font-bold text-center text-blue-900 mb-4">Contact Us</h2>
                    <p className="text-gray-700 text-center px-8 mb-12">We'd love to hear from you. As experts in domestic
                        condensation, we're here to help. Contact us using any of the methods below, or
                        schedule a video survey to find the best solution for your needs.</p>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">

                        {/*// <!-- Contact Form -->*/}
                        <div className="bg-white rounded-lg shadow-lg p-8">
                            <h3 className="text-2xl font-semibold text-blue-900 mb-4 text-center">Send Us a Message</h3>
                            <p className="text-gray-700 text-center mb-6">Complete the form below, and our team will be
                                in touch shortly.</p>
                            <form action="https://formsubmit.co/a2e32a1981c1b96ffd4400e4ae38b046" method="POST" className="space-y-4">
                                <input type="hidden" name="_next" value="https://dryhome.co/thanks-contact"/>
                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-1"
                                           htmlFor="name">Name</label>
                                    <input type="text" id="name" name="name" required
                                           className="w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-100"/>
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-1"
                                           htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" required
                                           className="w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-100"/>
                                </div>
                                <div>
                                    <label className="block text-gray-700 text-sm font-medium mb-1"
                                           htmlFor="message">Message</label>
                                    <textarea id="message" name="message" rows="4" required
                                              className="w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-100"></textarea>
                                </div>
                                <button type="submit"
                                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 rounded-lg transition duration-200">
                                    Submit
                                </button>
                            </form>
                        </div>

                        {/*// <!-- Contact Information -->*/}
                        <div className="bg-white rounded-lg shadow-lg p-8">
                            <h3 className="text-2xl font-semibold text-blue-900 mb-4 text-center">Get In Touch</h3>
                            <p className="text-gray-700 text-center mb-6">Reach out to us via phone, email, or through
                                our website and someone will be in touch very shortly.</p>

                            <div className="space-y-4">
                                <p className="text-gray-700">
                                    <strong>Phone:</strong><br/>
                                    01269 841314<br/>07971 433030
                                </p>
                                <p className="text-gray-700">
                                    <strong>Email:</strong><br/>
                                    <a href="mailto:info@dryhomecondensation.co.uk"
                                       className="text-blue-600 hover:underline">info@dryhomecondensation.co.uk</a>
                                </p>
                                <p className="text-gray-700">
                                    <strong>Website:</strong><br/>
                                    <a href="https://www.dryhomecondensation.co.uk"
                                       className="text-blue-600 hover:underline">dryhomecondensation.co.uk</a>
                                </p>
                                <p className="text-gray-700">
                                    <strong>Address:</strong><br/>
                                    Unit 6<br/>Cross Hands Business Workshops<br/>Cross Hands Business Park<br/>Cross Hands<br/>Llanelli<br/>SA14
                                    7NF
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*// <!-- Google Maps Embed -->*/}
                    <div className="mt-12 bg-white rounded-lg shadow-lg overflow-hidden">
                        <h3 className="text-2xl font-semibold text-blue-900 px-8 py-6">Our Location</h3>
                        <iframe className="w-full h-80 rounded-b-lg m-3"
                                title={"google-map-embed"}
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAaysqquY1CPneUsRQOcpe-qmlfiEkQPrU&q=place_id:ChIJyx-OC276bkgRRyi_L0gRCtY&key=AIzaSyAaysqquY1CPneUsRQOcpe-qmlfiEkQPrU"
                                allowFullScreen="" loading="lazy"
                                // style="border:0;"
                        >
                        </iframe>
                    </div>
                </div>
            </section>


            <Footer/>
        </div>
    );
}

export default Contact;
