import React, {useLayoutEffect, useState} from "react";
import Header from "../components/Header";
import {useProducts} from '../providers/ProductContext'
import {useCart} from "../hooks/UseCart";
import axios from 'axios';
import {VAT_RATE} from "../utils/Constants";
import PaymentForm from "../components/PaymentForm";
import {Elements} from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import DeliveryTradeBanner from "../components/DeliveryTradeBanner";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);


function Checkout() {
    //todo loading etc
    const {products} = useProducts();
    const {cart, removeFromCart, addToCart} = useCart(products);

    const getProduct = (productId) => {
        return products.find(product => product.id === productId);
    }

    const itemsInBasket = cart.map(item => {return {...item, ...getProduct(item.id)}})

    const subTotal = +itemsInBasket.reduce((a, b) => a + (b.price || 0) * (b.quantity || 0), 0).toFixed(2);
    const vatAmount = +(subTotal * (VAT_RATE / 100)).toFixed(2);
    const total = +(subTotal + +vatAmount).toFixed(2);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address1: '',
        address2: '',
        address3: '',
        town: '',
        postCode: '',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const cancelPayment = () => {
       setPendingOrderId(null);
       setStripeOptions(null);
    };

    const [pendingOrderId, setPendingOrderId] = useState(null);
    const [stripeOptions, setStripeOptions] = useState(null);

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Order to submit:', formData);

        try {
            //todo extract
            if (!formData.address2) {
                formData.address2 = null;
            }

            if (!formData.address3) {
                formData.address3 = null;
            }
            formData.subTotal = subTotal;
            formData.total = total;
            formData.items = itemsInBasket.map(i => ({productId: i.id, quantity: i.quantity, price: i.price}))
            const response = await axios.post(`${process.env.REACT_APP_DRYHOME_DB_URL}/api/pending-orders`, formData);

            const { id, clientSecret } = response.data;

            setStripeOptions( { clientSecret, appearance: { theme: 'stripe' } });
            setPendingOrderId(id);

            scrollToBottom();

        } catch (error) {
            console.error('Error during form submission:', error);
            //todo this needs to be better, and test more
            alert('Failed to submit. Please try again.');
        }

        // setSubmitted(true);
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>
            <div className="min-h-screen px-6 lg:px-8">
                {cart.length ? (
                    <div>
                        <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg p-6">
                            <h1 className="text-xl font-semibold">Checkout</h1>
                            <DeliveryTradeBanner/>

                            <ul className="divide-y divide-gray-200">
                                {itemsInBasket.map((item) => (
                                    <li key={item.id} className="flex justify-between items-center py-4">
                                        <div className="flex items-center">
                                            <img
                                                src={item.image}
                                                alt={item.name}
                                                className="w-16 h-16 rounded object-cover mr-4"
                                            />
                                            <div>
                                                <h2 className="text-lg font-semibold">{item.name}</h2>
                                                <p className="text-gray-500">£{item.price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                <p className="text-gray-500">Quantity: {item.quantity}</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <button
                                                className="bg-gray-200 text-gray-800 px-3 py-1 rounded hover:bg-gray-300"
                                                onClick={() => removeFromCart(item.id)}
                                            >
                                                -
                                            </button>
                                            <button
                                                className="bg-gray-800 text-white px-3 py-1 rounded hover:bg-gray-900"
                                                onClick={() => addToCart(item.id)}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            <div className="mt-6 text-right">
                                <h3 className="text-l py-1">
                                    Sub Total: £{subTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h3>
                                <h3 className="text-l py-1">
                                    VAT ({VAT_RATE}%): £{vatAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h3>

                                <h3 className="text-l font-semibold py-1">
                                    Total Price: £{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h3>

                            </div>

                            <form
                                onSubmit={handleSubmit}
                                className="bg-white p-8 max-w-md w-full"
                            >
                                {!stripeOptions && <p className={"mb-2"}>We just need some details to take your order</p>}

                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        required
                                        disabled={stripeOptions}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        required
                                        disabled={stripeOptions}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        required
                                        disabled={stripeOptions}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="address1" className="block text-sm font-medium text-gray-700">
                                        Address Line 1
                                    </label>
                                    <input
                                        type="address1"
                                        name="address1"
                                        id="address1"
                                        value={formData.address1}
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        required
                                        disabled={stripeOptions}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="address2" className="block text-sm font-medium text-gray-700">
                                        Address Line 2
                                    </label>
                                    <input
                                        type="address2"
                                        name="address2"
                                        id="address2"
                                        value={formData.address2}
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        disabled={stripeOptions}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="address3" className="block text-sm font-medium text-gray-700">
                                        Address Line 3
                                    </label>
                                    <input
                                        type="address3"
                                        name="address3"
                                        id="address3"
                                        value={formData.address3}
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        disabled={stripeOptions}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="town" className="block text-sm font-medium text-gray-700">
                                        Town
                                    </label>
                                    <input
                                        type="town"
                                        name="town"
                                        id="town"
                                        value={formData.town}
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        required
                                        disabled={stripeOptions}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="postCode" className="block text-sm font-medium text-gray-700">
                                        Post Code
                                    </label>
                                    <input
                                        type="postCode"
                                        name="postCode"
                                        id="postCode"
                                        value={formData.postCode}
                                        onChange={handleChange}
                                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        required
                                        disabled={stripeOptions}
                                    />
                                </div>

                                {!stripeOptions && <button
                                    type="submit"
                                    className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    Continue to Payment
                                </button>}
                            </form>
                            {/*todo needs a loading state*/}
                            {stripeOptions && <div><Elements stripe={stripePromise} options={stripeOptions}>
                                <div>
                                    <p className={"mb-2"}>And finally your payment details</p>
                                    <PaymentForm pendingOrderId={pendingOrderId} />
                                </div>
                            </Elements>
                                <button onClick={cancelPayment}
                                        className="w-full bg-red-600 text-white my-2 py-2 px-4 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    Cancel
                                </button>
                                </div>}
                <p className={"my-4"}>Not done yet? <Link to="/shop" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Continue Shopping</Link></p>
                        </div>
                        <div className={"pt-80"}></div>
                    </div>

                ) : (<div><p>Your cart is empty</p> <Link to="/shop" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Shop our products now</Link></div>)}
            </div>
            <Footer/>
        </div>
    );
}

export default Checkout;
