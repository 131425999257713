import {Link} from 'react-router-dom';
import {FaShoppingBasket} from "react-icons/fa";
import React, {useState} from "react";

const Header = ({cart}) => {
    const totalQty = cart.reduce((acc, item) => acc + item.quantity, 0);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div>
            {/*normal version*/}
            <header className="absolute inset-x-0 top-0 z-50 hidden sm:block bg-slate-300 rounded-b-lg">
                <nav className="flex items-center justify-between p-2 lg:px-8 flex-col sm:flex-row" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Dryhome Condensation</span>
                            <img className="h-16 w-auto" src="dryhome-logo-transparent.png" alt="Dryhome Logo"/>

                        </Link>

                    </div>
                    <div className="flex gap-x-12">
                        <div><Link to="/"
                                className="text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold">Home</Link>
                        </div>
                        <div><Link to="/remcon"
                                className="text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold">The
                            REMCON</Link></div>

                        <div className="relative group">
                            <button
                                className="text-sm font-semibold leading-6 text-gray-800 group-hover:text-black group-hover:font-bold">
                                Customers
                            </button>

                            {/*// <!-- Dropdown Menu -->*/}
                            <div
                                className="absolute left-0 mt-0 hidden group-hover:flex flex-col bg-slate-300 border border-slate-400 shadow-lg rounded-lg z-10 w-40">
                                <Link to="/domestic"
                                   className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">Domestic</Link>
                                <Link to="/trade"
                                   className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">Trade</Link>
                                <Link to="/landlords"
                                   className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">Landlords</Link>
                            </div>
                        </div>

                        <div><Link to="/faq"
                                className="text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold">FAQ</Link>
                        </div>


                        <div><Link to="/shop"
                                className="text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold">Shop</Link>
                        </div>
                        <div className="relative group">
                            <button
                                className="text-sm font-semibold leading-6 text-gray-800 group-hover:text-black group-hover:font-bold">
                                About
                            </button>
                            <div
                                className="absolute left-0 mt-0 hidden group-hover:flex flex-col bg-slate-300 border border-slate-400 shadow-lg rounded-lg z-10 w-40">
                                <Link to="/about"
                                   className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">
                                    About Us
                                </Link>
                                <Link to="/testimonials"
                                   className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">
                                    Testimonials
                                </Link>
                                <Link to="/contact"
                                   className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">
                                    Contact Us
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className="flex lg:flex-1 lg:justify-end">
                        <Link to="/checkout">
                            <div className="relative inline-flex items-center px-1">
                                {/* Shopping Basket Icon */}
                                <FaShoppingBasket className=" w-12 h-12"/>

                                {/* Badge for the number */}
                                {totalQty > 0 &&
                                    <span
                                        className="absolute top-0 right-0 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full">
                                {totalQty}
                                </span>}
                            </div>
                        </Link>
                    </div>
                </nav>
                {/*</div>*/}
            </header>

            {/*mobile version*/}
            <header className="absolute inset-x-0 top-0 z-50 sm:hidden block bg-slate-300 rounded-b-lg">
                <nav className="flex items-center justify-between p-2 lg:px-8 " aria-label="Global">
                    <div className="flex">
                        <button
                            onClick={toggleMobileMenu}
                            className="block md:hidden p-2"
                            aria-label="Toggle menu"
                        >
                            <div className="w-6 h-0.5 bg-black mb-1"></div>
                            <div className="w-6 h-0.5 bg-black mb-1"></div>
                            <div className="w-6 h-0.5 bg-black"></div>
                        </button>

                        {isMobileMenuOpen && (
                            <div className="md:hidden">

                                <Link to="/"
                                   className="block p-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold">Home</Link>
                                <Link to="/remcon"
                                   className="block p-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold">The
                                    REMCON</Link>
                                <div className="relative group">
                                    <button
                                        className="block p-2 text-sm font-semibold leading-6 text-gray-800 group-hover::text-black group-hover:font-bold">Customers
                                    </button>
                                    <div
                                        className="absolute left-0 mt-0 hidden group-hover:flex flex-col bg-slate-300 border border-slate-400 shadow-lg rounded-lg z-10 w-40">
                                        <Link to="/domestic"
                                           className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">Domestic</Link>
                                        <Link to="/trade"
                                           className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">Trade</Link>
                                        <Link to="/landlords"
                                           className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">Landlords</Link>
                                    </div>
                                </div>
                                <Link to="/faq"
                                   className="block p-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold">FAQ</Link>
                                <Link to="/shop"
                                   className="block p-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold">Shop</Link>
                                <div className="relative group">

                                    <button
                                        className="block p-2 text-sm font-semibold leading-6 text-gray-800 group-hover:text-black group-hover:font-bold">About
                                    </button>
                                    <div
                                        className="absolute left-0 mt-0 hidden group-hover:flex flex-col bg-slate-300 border border-slate-400 shadow-lg rounded-lg z-10 w-40">
                                        <Link to="/about"
                                           className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">
                                            About Us
                                        </Link>
                                        <Link to="/testimonials"
                                           className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">
                                            Testimonials
                                        </Link>
                                        <Link to="/contact"
                                           className="px-4 py-2 text-sm font-semibold leading-6 text-gray-800 hover:text-black hover:font-bold w-full text-left">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                    <Link to="/" className="-m-1.5 p-1.5 items-center flex">
                        <span className="sr-only">Dryhome Condensation</span>
                        <img className="h-16 w-auto" src="dryhome-logo-transparent.png" alt=""/>

                    </Link>

                    <div className="flex lg:flex-1 lg:justify-end">
                        <Link to="/checkout">
                            <div className="relative inline-flex items-center px-1">
                                {/* Shopping Basket Icon */}
                                <FaShoppingBasket className=" w-12 h-12"/>

                                {/* Badge for the number */}
                                {totalQty > 0 &&
                                    <span
                                        className="absolute top-0 right-0 inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full">
                                {totalQty}
                                </span>}
                            </div>
                        </Link>
                    </div>

                </nav>
            </header>
        </div>
    );
};

export default Header;
