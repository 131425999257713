import React, {useState} from "react";
import {Dialog} from "@headlessui/react";

const Gallery = () => {
    const images = [
        {path: "/gallery/in-situ.jpg", alt: "The REMCON in situ"},
        {path: "/gallery/in-situ-closeup.jpg", alt: "The REMCON in situ clse up"},
        {path: "/gallery/in-situ-wide-angle.jpg", alt: "The REMCON in situ wide angled"},
        {path: "/gallery/infographic-closeup.jpg", alt: "The REMCON infographic closeup"},
        {path: "/gallery/infographic.jpg", alt: "The REMCON infographic"},
        {path:  "/gallery/remcon-filter.jpg", alt: "The REMCON filter"},
        {path: "/gallery/remcon-filter-angled.jpg", alt: "The REMCON filter angled"},
        {path: "/gallery/remcon-filter-flat.jpg", alt: "The REMCON filter flat"},
        {path: "/gallery/remcon-filter-with-cage.jpg", alt: "The REMCON filter with cage"},
        {path: "/gallery/remcon-louvre-fan.jpg", alt: "The REMCON Louvre and Fan"},
        {path: "/gallery/remcon-louvre-filter-remote.jpg", alt: "The REMCON Louvre, filter and remote"},
        {path: "/gallery/remcon-louvre-flat.jpg", alt: "The REMCON louvre flat"},
        {path: "/gallery/remcon-remote.jpg", alt: "The REMCON remote control"},
        {path: "/gallery/remcon-unit-filter-remote.jpg", alt: "The REMCON unit, filter and remote"},
        {path: "/gallery/remcon-unit-with-remote.jpg", alt: "The REMCON unit with remote"},
        {path: "/gallery/remcon-with-display-and-filter-upside-down.jpg", alt: "The REMCON with display and filter upside down"},
        {path: "/gallery/remcon-with-display-and-filter-upside-down-angled.jpg", alt: "The REMCON with display and filter upside down angled"},
        {path: "/gallery/remcon-with-filter-and-remote-angled.jpg", alt: "The REMCON with filter and remote angled"},
        {path: "/gallery/remcon-with-filter-back.jpg", alt: "The REMCON with filter back"},
        {path: "/gallery/remcon-with-filter-diagonal.jpg", alt: "The REMCON with filter diagonal"},
        {path: "/gallery/remcon-with-filter-side-on.jpg", alt: "The REMCON with filter side on"},
        {path: "/gallery/remcon-with-filter-side-on-angled.jpg", alt: "The REMCON with filter side on angled"},
    ]

    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openModal = (index) => {
        setCurrentIndex(index);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const nextImage = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((currentIndex + images.length - 1) % images.length);
    };

    return (
        <div className="p-4 bg-gray-50" id={"gallery"}>
            {/* Gallery Grid */}
            <div className="text-center ">
                <h3 className="text-2xl font-bold text-blue-900 my-4">Gallery</h3>
                <p className="text-gray-700 pb-6">Check out the images of the REMCON and accessories below</p>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="cursor-pointer overflow-hidden rounded-lg shadow hover:scale-105 transform transition duration-300"
                        onClick={() => openModal(index)}
                    >
                        <img
                            src={image.path}
                            alt={image.alt}
                            className="w-full h-auto object-cover"
                        />
                    </div>
                ))}
            </div>

            {/* Modal */}
            {isOpen && (
                <Dialog open={isOpen} onClose={closeModal}
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black/70">
                    <div className="relative max-w-4xl mx-auto">
                        {/* Image */}
                        <img src={images[currentIndex].path} alt={images[currentIndex].alt}
                             className="max-w-full max-h-screen rounded-lg"/>

                        {/* Navigation Buttons */}
                        <button
                            onClick={prevImage}
                            className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-black p-2 rounded-full shadow hover:bg-gray-200"
                        >
                            ◀
                        </button>
                        <button
                            onClick={nextImage}
                            className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-black p-2 rounded-full shadow hover:bg-gray-200"
                        >
                            ▶
                        </button>

                        {/* Close Button */}
                        <button
                            onClick={closeModal}
                            className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow hover:bg-gray-200"
                        >
                            ✖
                        </button>

                        {/* Download Button */}
                        <a
                            href={images[currentIndex]}
                            download={`Image-${currentIndex + 1}`}
                            className="absolute bottom-4 right-4 bg-white text-black py-1 px-3 rounded-lg shadow hover:bg-gray-200"
                        >
                            Download
                        </a>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default Gallery;
