import {useState, useEffect, createContext, useContext} from 'react';

const ALL_PRODUCTS = [
    {
        id: 6,
        displayOrder: 10,
        name: "REMCON Whole Home Ventilation system",
        shortName: "REMCON",
        description: "REMCON Whole Home Ventilation System",
        image: "remcon-unit-with-remote-small.jpg"
    },
    {
        id: 5,
        displayOrder: 20,
        name: "REMCON replacement filter",
        shortName: "Filter",
        description: "REMCON Replacement Filter",
        image: "remcon-filter-flat.jpg"
    },
    {
        id: 8,
        displayOrder: 30,
        name: "REMCON replacement remote",
        shortName: "Remote",
        description: "REMCON Replacement Remote Control",
        image: "remcon-remote.jpg"
    },
]

const ProductContext = createContext();

export function ProductProvider({ children }) {


    const [products, setProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [loadingProductsError, setLoadingProductsError] = useState(null);

    //this will change to a REST call to the backend. It will then need a 'loading...' state etc

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                console.log(`Looking up products`);
                const response = await fetch(`${process.env.REACT_APP_DRYHOME_DB_URL}/api/products`);
                if (!response.ok) {
                    throw new Error('Failed to fetch products');
                }
                const data = await response.json();

                const productsToStore = [];

                data.forEach(product => {
                    const productToStore = ALL_PRODUCTS.find(p => p.id === product.id)

                    if (productToStore) {
                        productsToStore.push({price: product.rrp, ...productToStore});
                    }
                });
                productsToStore.sort((a, b) => a.displayOrder - b.displayOrder)


                setProducts(productsToStore);
            } catch (error) {
                console.log(`Error when looking up products: ${error}`);
                setLoadingProductsError(error.message);
            } finally {
                setLoadingProducts(false);
            }
        };
        fetchProducts();
    }, []);

    return (
        <ProductContext.Provider value={{ products,  loadingProducts, loadingProductsError}}>
            {children}
        </ProductContext.Provider>
    );
}

export function useProducts() {
    return useContext(ProductContext);
}
