import React from "react";
import Header from "../components/Header";
import {useProducts} from "../providers/ProductContext";
import {useCart} from "../hooks/UseCart";
import Footer from "../components/Footer";

function NotFound() {

    const {products} = useProducts();
    const { cart} = useCart(products);

    return (
        <div className="bg-white relative pt-20">
            <Header cart={cart}/>
            <div className="min-h-screen px-6 pt-8 lg:px-8">
                <h1>Page Not Found</h1>
            </div>
            <Footer/>
        </div>
    );
}

export default NotFound;
