import React, {useLayoutEffect} from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {useProducts} from '../../providers/ProductContext'
import {useCart} from '../../hooks/UseCart';

function Testimonials() {
    const {products} = useProducts();
    const {cart} = useCart(products);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>

            <section className="bg-gray-50 py-16">
                <div className="container mx-auto px-6 lg:px-12 max-w-6xl">
                    <h2 className="text-4xl font-bold text-center text-blue-900 mb-12">What Our Customers Say</h2>

                    <p className="text-l text-center text-gray-500 mb-12">Don't take our word, see what others have to
                        say about our REMCON ventilation system</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"Since the system has been installed it has
                                totally changed my life"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mrs Trisha Thomas</p>
                                    <p className="text-gray-500 text-sm">Llanelli</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"I’ve recommended this company hundreds of times
                                when I inspect properties for damp and the unit is highly recommended by my
                                customers."</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">A & R Damp
                                        Proofing</p>
                                    <p className="text-gray-500 text-sm">Swansea</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"The Dryhome team were efficient and considerate,
                                the condensation problem has been solved. I am happy to recommend Dryhome Condensation
                                Ltd"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mr Tariq Jennings</p>
                                    <p className="text-gray-500 text-sm">Cheltenham</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"The condensation, dampness and musty smells have
                                disappeared. The air that we breath is much cleaner and the whole house seems to be
                                fresher and drier. I can thoroughly recommend this unit. A must if you suffer from any
                                of the above mentioned problems in your home"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mr Birch</p>
                                    <p className="text-gray-500 text-sm">Abertillery</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"What a brilliant product. We even considered
                                moving house because of the condensation problem.
                                It is worth its weight in gold"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mrs Lesley Nuttall</p>
                                    <p className="text-gray-500 text-sm">Stoke on Trent</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"I would like to thank you for your general work
                                attitude, cleanliness, politeness and sound advice you gave us regarding the
                                condensation problem we were experiencing. I would definitely recommend you to anyone
                                with the same problem we had"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mrs Rees</p>
                                    <p className="text-gray-500 text-sm">Bridgend</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"I would happily recommend you to anyone who is
                                looking for excellent service at a reasonable price"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mr RF Curtis</p>
                                    <p className="text-gray-500 text-sm">Whitstable</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"I am writing to you to say how happy I am with
                                the system, from the day it is installed we have not seen any sign of black mould
                                growth, mildew or damp marks anywhere in the house. I must admit I don’t know how it
                                works, but it certainly DOES !"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mr Tony Brighton</p>
                                    <p className="text-gray-500 text-sm">Swansea</p>
                                </div>
                            </div>
                        </div>

                        {/*newer ones...*/}

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-2">"Simply fantastic!</p>
                            <p className="text-gray-700 text-lg mb-2">We have a 1980s bungalow that suffered very badly
                                with streaming windows every day through the autumn and winter. I was recommended the
                                Remcon unit and a few days over 5 years ago I installed it and we have never had the
                                problem from day 1. And it stays on 24/7 all year as it does help cool and regulate some
                                in the summer also.</p>
                            <p className="text-gray-700 text-lg mb-2">I can't recommend this enough, Dryhome are very
                                easy to talk with, informative and helpful - I am now talking with them on a follow on
                                order.</p>
                            <p className="text-gray-700 text-lg mb-2">If you suffer from any kind of damp, streaming
                                windows, etc. Then this is the product for you. You will not be disappointed!</p>
                            <p className="text-gray-700 text-lg mb-4">Thanks to Dryhome for your product and
                                support."</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mr Trevor Preston</p>
                                    <p className="text-gray-500 text-sm">Telford</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-2">"The Remcon has changed my life and that of my
                                husband. I had heard of it from a member of my family years ago ,but kept putting it off
                                thinking things would get better. It didn,t, I was embarrassed that my house was dirty,
                                Kept cleaning the black spots but they always came back.</p>
                            <p className="text-gray-700 text-lg mb-2"> The final straw was when I found spots on my
                                mattress when I pulled the bed away from the wall. Obviously the small condensers I had
                                around the house were not working.</p>
                            <p className="text-gray-700 text-lg mb-2"> I got in touch with Anthony and he was able to
                                fit me in later that week. The installation took 1 hour, no mess and Anthony was very
                                helpful, polite and friendly.</p>
                            <p className="text-gray-700 text-lg mb-2"> Now I have NO condensation on the windows.</p>
                            <p className="text-gray-700 text-lg mb-2"> NO more black spots.</p>
                            <p className="text-gray-700 text-lg mb-2"> Air is fresher in the house with no more musty
                                smells, even in my wardrobe.</p>
                            <p className="text-gray-700 text-lg mb-2"> BUT THE MOST IMPORTANT THING.</p>
                            <p className="text-gray-700 text-lg mb-2"> My husband had, had a cough for a few years and
                                doctors couldn,t find anything wrong, next plan was to have a scan. He was getting
                                worried.</p>
                            <p className="text-gray-700 text-lg mb-2"> Hey guess what. His cough has GONE. since we have
                                had this unit installed. My son has now installed one in his house because my grandson
                                has asthma. No more condensation for him either.</p>
                            <p className="text-gray-700 text-lg mb-4"> Can't recommend it enough, Thank you!"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Ruth Morgan</p>
                                    <p className="text-gray-500 text-sm">Gorslas</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-2">"It is highly recommended that every household
                                invest in a Dryhome Remcon Unit. Last year, while decorating my parents' home, I noticed
                                black patches caused by dampness, which was affecting my mother's health, as she was
                                suffering from Interstitial Lung Disease. Her severe cough had progressed to the point
                                where she could no longer eat. Hence, I decided to explore installing a damp unit in the
                                attic. After conducting some research, I came across Dryhome, and after a few
                                conversations with their team, I decided to go ahead with the installation.</p>

                            <p className="text-gray-700 text-lg mb-2"> Anthony, who is courteous, organized, and highly knowledgeable, installed the unit and
                                took care of all the electrical work in the attic. The day after installation, there was
                                no condensation on any windows, and within a couple of days, my mother's cough had
                                completely subsided, allowing her to eat normally again. Furthermore, after several
                                months, there has been no return of black patches. This unit has been a game-changer for
                                my mother's health, and I highly recommend it to anyone with respiratory issues or
                                persistent coughing.</p>

                            <p className="text-gray-700 text-lg mb-2">One of the most significant advantages of the Remcon unit is that you will never need to
                                use a dehumidifier again. It also filters the air, making it an ideal addition to homes
                                with infants and the elderly. The Remcon unit is worth its weight in gold, and I can
                                guarantee that you will never regret having it installed. My mother describes it as the
                                best machine she has ever had, and she would not be here today without it.</p>

                            <p className="text-gray-700 text-lg mb-4"> Overall, Dryhome is an exceptional company with a remarkable product."</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Mary Mitchell</p>
                                    <p className="text-gray-500 text-sm">Llanelli</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-2">"I had the remcon unit fitted by Anthony a few
                                months back but wanted to wait a little while before writing a review.</p>

                            <p className="text-gray-700 text-lg mb-2">From start to finish dealing with the company was easy. They were friendly, helpful and
                                answered all my questions. Nothing was too much trouble.</p>

                                <p className="text-gray-700 text-lg mb-2">Anthony came and fitted the unit after assesing my house to make sure it was suitable
                                for my needs before going ahead. He was respectful of my home throughout and cleared up
                                    all mess once the job was completed.</p>

                                    <p className="text-gray-700 text-lg mb-2">Now, the big question, has it worked? The answer is a simple one, yes!! Prior to
                                installation I was plagued by dripping windows, puddles on windowsills and unsightly
                                black mould in certain areas of my house. Quite frankly, it was embarrassing 😳 This
                                cleared up really quickly after the unit was installed, and I'm pleased to say it has
                                not returned!! Yes, this unit is a tad more expensive than others, but it is absolutely
                                worth it as you can really tweak the settings so it is perfect for your home! It can
                                        also be turned off in warmer months where others cannot be.</p>

                                        <p className="text-gray-700 text-lg mb-4">All in all, a fantastic piece of kit that really does do what it says on the tin and
                                excellent, friendly service from a local company!! If, like me, your home is plagued by
                                condensation, then I cannot recommend this company enough!! Thanks Anthony 😊"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Karen James</p>
                                    <p className="text-gray-500 text-sm">Rhigos</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <p className="text-gray-700 text-lg mb-4">"Very helpful, had this installed 5 years ago and
                                the condensation problem has completely gone!"</p>
                            <div className="flex items-center mt-4">
                                <div>
                                    <p className="font-semibold text-blue-900">Graham Pennock</p>
                                    <p className="text-gray-500 text-sm">Swansea</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <Footer/>
        </div>
    );
}

export default Testimonials;
