import React, {useLayoutEffect} from "react";
import {Link} from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {useProducts} from '../providers/ProductContext'
import {useCart} from '../hooks/UseCart';
import ImageCarousel from "../components/Carousel";

function Home() {
    const {products} = useProducts();
    const {cart} = useCart(products);


    const images = [
        'in-situ-medium.jpg',
        'in-situ-closeup.jpg',
        'in-situ-wide-angle.jpg',
        'remcon-unit-with-remote-small.jpg',
        'infographic-closeup.jpg',
        'infographic.jpg'
    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>
            {/*<div className="min-h-screen px-6 lg:px-8">*/}
            {/*    <h3 className="p-8 text-center">Manufacturers of the REMCON whole home ventilation system </h3>*/}

            {/*</div>*/}

            {/*// <!-- Hero Section -->*/}
            <section className="bg-blue-100 text-blue-900 py-16 mb-8 text-center">
                <div className="container mx-auto px-6 space-y-6">
                    <h2 className="text-5xl font-bold mb-4">Take Control of Condensation</h2>
                    <p className="text-2xl font-medium mb-2">
                        <span className="text-indigo-700 font-semibold">Manufacturers</span> of the REMCON Whole Home
                        Ventilation System
                    </p>
                    <p className="text-lg font-semibold text-blue-800 mb-6">Specialists in Domestic Condensation
                        Control</p>
                    <p className="text-lg font-semibold uppercase tracking-wider text-gray-700 mb-10">
                        Patented • 5 Year Guarantee • Remote Control
                    </p>
                    <div className="space-x-4">
                        <Link to="/contact"
                           className="inline-block bg-blue-900 hover:bg-blue-700 text-white font-semibold py-3 px-6 mt-3 rounded-lg transition-all duration-100">
                            Contact Us Today
                        </Link>
                        <Link to="/shop"
                           className="inline-block bg-blue-900 hover:bg-blue-700 text-white font-semibold py-3 px-6 mt-3 rounded-lg transition-all duration-100">
                            Shop Now
                        </Link>
                    </div>
                </div>
            </section>

            <ImageCarousel images={images} />

            <div className="space-x-4 text-center my-2">
                <Link to="/remcon"
                   className="inline-block bg-blue-900 hover:bg-blue-700 text-white font-semibold py-3 px-6 mt-3 rounded-lg transition-all duration-100">
                    Read about the REMCON
                </Link>
            </div>

            <section className="container mx-auto px-6 py-12 grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold text-blue-900">Easily Installed</h3>
                    <p className="mt-4 text-gray-600">In houses and bungalows</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold text-blue-900">5-Year Guarantee</h3>
                    <p className="mt-4 text-gray-600">The REMCON comes with a comprehensive 5-year parts and labour
                        warranty.</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold text-blue-900">Controls</h3>
                    <p className="mt-4 text-gray-600">Condensation, Mildew, Mould, & Dampness.</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold text-blue-900">99 Speed Setting</h3>
                    <p className="mt-4 text-gray-600">Remote Control.</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold text-blue-900">Low Maintenance</h3>
                    <p className="mt-4 text-gray-600">Low cost filter maintenance annually.</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-6">
                    <h3 className="text-xl font-semibold text-blue-900">Healthier</h3>
                    <p className="mt-4 text-gray-600">Provides a healthier way of living.</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-6 col-span-1 md:col-span-3">
                    <h3 className="text-xl font-semibold text-blue-900">Energy Efficient</h3>
                    <p className="mt-4 text-gray-600">Low running costs, better utilisation of existing heating.</p>
                </div>
            </section>


            {/*// <!-- Testimonials Section -->*/}
            <section class="bg-blue-900 text-white py-12">
                <div class="container mx-auto px-6 text-center">
                    <h2 class="text-3xl font-bold mb-4">What Our Customers Say</h2>
                    <p class="mb-8 text-lg">Here’s how the REMCON Whole Home Ventilation System is making a difference in homes across the country.</p>

                    <div className="flex space-x-1 justify-center mb-6">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"
                             className="w-6 h-6 text-white">
                            <path
                                d="M12 .587l3.668 7.431 8.332 1.209-6.04 5.884 1.423 8.289L12 18.896l-7.383 3.881 1.423-8.289-6.04-5.884 8.332-1.209z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"
                             className="w-6 h-6 text-white">
                            <path
                                d="M12 .587l3.668 7.431 8.332 1.209-6.04 5.884 1.423 8.289L12 18.896l-7.383 3.881 1.423-8.289-6.04-5.884 8.332-1.209z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"
                             className="w-6 h-6 text-white">
                            <path
                                d="M12 .587l3.668 7.431 8.332 1.209-6.04 5.884 1.423 8.289L12 18.896l-7.383 3.881 1.423-8.289-6.04-5.884 8.332-1.209z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"
                             className="w-6 h-6 text-white">
                            <path
                                d="M12 .587l3.668 7.431 8.332 1.209-6.04 5.884 1.423 8.289L12 18.896l-7.383 3.881 1.423-8.289-6.04-5.884 8.332-1.209z"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"
                             className="w-6 h-6 text-white">
                            <path
                                d="M12 .587l3.668 7.431 8.332 1.209-6.04 5.884 1.423 8.289L12 18.896l-7.383 3.881 1.423-8.289-6.04-5.884 8.332-1.209z"/>
                        </svg>
                    </div>


                    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div class="bg-white text-gray-800 shadow-md rounded-lg p-6">
                            <p>"Since the system has been installed it has
                                totally changed my life"</p>
                            <p class="mt-4 font-semibold">– Mrs Trisha Thomas</p>
                        </div>
                        <div class="bg-white text-gray-800 shadow-md rounded-lg p-6">
                            <p>"I’ve recommended this company hundreds of times"</p>
                            <p class="mt-4 font-semibold">– A & R Damp Proofing</p>
                        </div>
                        <div class="bg-white text-gray-800 shadow-md rounded-lg p-6">
                            <p>"Very helpful, had this installed 5 years ago and
                                the condensation problem has completely gone!"</p>
                            <p class="mt-4 font-semibold">– Graham Pennock</p>
                        </div>
                    </div>
                    <Link to="/testimonials"
                          className="inline-block bg-gray-300 hover:bg-white text-gray-900 font-semibold mt-6 py-3 px-6 mt-3 rounded-lg transition-all duration-100">
                        Read our Testimonials
                    </Link>
                </div>
            </section>

            <section className="py-12 bg-blue-100">
                <h2 className="text-3xl font-bold text-blue-900 text-center mb-6">Our Customers</h2>

                <div className="container mx-auto px-6 space-y-12">
                    {/*// <!-- Solution for Domestic Use -->*/}
                    <div className="flex flex-col md:flex-row items-center md:space-x-6 space-y-6 md:space-y-0">
                        {/*<img src="https://via.placeholder.com/400x300" alt="Domestic use"*/}
                        <img src="./family.jpg" alt="Domestic use"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>
                            <div className="text-center md:text-left md:w-1/2">
                                <h3 className="text-2xl font-bold text-blue-900 mb-4">Solutions for Domestic Use</h3>
                                <p className="text-gray-700">
                                    For homeowners who are concerned about condensation, black spot mould, mould growth, mildew, asthma and indoor air quality problems in their home.

                                </p>
                                <Link to="/domestic"
                                   className="inline-block bg-blue-900 hover:bg-blue-700 text-white font-semibold py-3 px-6 mt-3 rounded-lg transition-all duration-100">
                                    Read More
                                </Link>
                            </div>
                    </div>

                    {/*// <!-- Solution for Trade -->*/}
                    <div className="flex flex-col md:flex-row-reverse items-center md:space-x-6 space-y-6 md:space-y-0">
                        {/*<img src="https://via.placeholder.com/400x300" alt="Trade use"*/}
                        <img src="./builder.jpg" alt="Trade use"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>
                            <div className="text-center md:text-left md:w-1/2">
                                <h3 className="text-2xl font-bold text-blue-900 mb-4">Solutions for Trade</h3>
                                <p className="text-gray-700">
                                    For any type of tradesmen, including damp proofers, contractors, installers or distributors who provide solutions for homeowners with condensation problems, mould growth and dampness.
                                </p>
                                <Link to="/trade"
                                   className="inline-block bg-blue-900 hover:bg-blue-700 text-white font-semibold py-3 px-6 mt-3 rounded-lg transition-all duration-100">
                                    Read More
                                </Link>
                            </div>
                    </div>

                    {/*// <!-- Solution for Landlords -->*/}
                    <div className="flex flex-col md:flex-row items-center md:space-x-6 space-y-6 md:space-y-0">
                        {/*<img src="https://via.placeholder.com/400x300" alt="Landlords"*/}
                        <img src="./landlord.jpg" alt="Landlords"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>
                            <div className="text-center md:text-left md:w-1/2">
                                <h3 className="text-2xl font-bold text-blue-900 mb-4">Solutions for Landlords</h3>
                                <p className="text-gray-700">
                                    For private landlords, local authorities and housing associations who are concerned about condensation, black spot mould, mould growth, mildew, asthma and indoor air quality problems in their properties.

                                </p>
                                <Link to="/landlords"
                                   className="inline-block bg-blue-900 hover:bg-blue-700 text-white font-semibold py-3 px-6 mt-3 rounded-lg transition-all duration-100">
                                    Read More
                                </Link>
                            </div>
                    </div>
                </div>
            </section>

            {/*// <!-- Contact Section -->*/}
            <section id="contact" class="bg-blue-50 py-12">
                <div class="container mx-auto px-6 text-center">
                    <h2 class="text-3xl font-bold text-blue-900 mb-4">Get in Touch</h2>
                    <p class="text-gray-700 mb-6">Contact us to learn how the REMCON Whole Home Ventilation System can help you. For homeowners, prevent condensation and protect your home; for trade customers, add a new revenue stream to your business; and for landlords, enjoy peace of mind with long-term property protection.</p>
                    <Link to="/contact" class="inline-block bg-blue-900 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg">
                        Contact Us
                    </Link>
                </div>
            </section>

            <Footer/>
        </div>
    );
}

export default Home;
