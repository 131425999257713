import React, {useEffect} from "react";
import Header from "../components/Header";
import {useProducts} from '../providers/ProductContext'
import {useCart} from "../hooks/UseCart";
import {Link} from "react-router-dom";
import Footer from "../components/Footer";

function ThanksContact() {
    const {products} = useProducts();
    const {clearCart} = useCart(products);

    useEffect(() => {
        clearCart();
    }, [clearCart]); // Empty dependency array ensures it runs only once



    return (
        <div className="bg-white relative pt-20">
            <Header cart={[]}/>
            <section className="bg-blue-50 py-12 px-6 text-center">
                <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-8">
                    <h2 className="text-2xl md:text-3xl font-bold text-blue-900">Thank You for Your Message!</h2>
                    <p className="mt-4 text-gray-600">
                        We appreciate you getting in touch. One of our team members will respond to your message
                        shortly.
                    </p>
                    <div className="mt-8 space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row justify-center">
                        <Link to="/shop"
                           className="bg-blue-600 text-white px-6 py-3 rounded-lg text-sm font-semibold hover:bg-blue-700 transition">
                            Shop Now
                        </Link>
                        <Link to="/remcon"
                           className="bg-blue-100 text-blue-600 px-6 py-3 rounded-lg text-sm font-semibold hover:bg-blue-200 transition">
                            Learn More About the REMCON
                        </Link>
                        <Link to="/faq"
                           className="bg-gray-100 text-gray-700 px-6 py-3 rounded-lg text-sm font-semibold hover:bg-gray-200 transition">
                            Read Our FAQs
                        </Link>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}

export default ThanksContact;
