// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Shop from './pages/Shop';
import Home from './pages/Home';
import Checkout from './pages/Checkout';
import OrderConfirmed from './pages/OrderConfirmed';
import OrderFailed from './pages/OrderFailed';
import Info from './pages/Info';
import NotFound from './pages/NotFound';
import Remcon from "./pages/Remcon";
import Domestic from "./pages/customers/Domestic";
import Trade from "./pages/customers/Trade";
import Landlords from "./pages/customers/Landlords";
import FAQ from "./pages/faq";
import Testimonials from "./pages/about/testimonials";
import About from "./pages/about/About";
import Contact from "./pages/about/Contact";
import ThanksContact from "./pages/ThanksContact";

function App() {
    return (
        <Router>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/remcon" element={<Remcon />} />
                <Route path="/domestic" element={<Domestic />} />
                <Route path="/trade" element={<Trade />} />
                <Route path="/landlords" element={<Landlords />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/about" element={<About />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/thanks-contact" element={<ThanksContact />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/orderConfirmed" element={<OrderConfirmed />} />
                <Route path="/orderFailed" element={<OrderFailed />} />
                <Route path="/info" element={<Info />} />
                <Route path="*" element={<NotFound />} /> {/* This will catch any unmatched route */}
            </Routes>
        </Router>
    );
}

export default App;
