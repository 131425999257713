import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

function OrderFailed() {
    return (
        <div className="bg-white relative pt-20">
            <Header cart={[]}/>
            <div className="min-h-screen px-6 pt-8 lg:px-8">
                <div>
                    <p className={"text-2xl"}>There seems to have been a problem placing your order</p>
                    <p className={"my-4"}>Head <Link to="/shop" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Back to Shop</Link> to try again or feel free to <Link to="/" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Contact Us</Link> and we will be able to help quickly</p>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default OrderFailed;
