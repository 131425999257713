import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PaymentForm = ({ pendingOrderId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);
    const [success] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);

        if (!stripe || !elements) return;

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_DRYHOME_DB_URL}/api/pending-orders/` + pendingOrderId + "/complete",
            },
        });

        console.log(`result... ${JSON.stringify(result)}`)
        console.log(`result error... ${JSON.stringify(result.error)}`)

        if (result.error) {
            setError(`there was an error: ${JSON.stringify(result.error)}`);
            setProcessing(false);
        } else {
            //should redirect to backend
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {/*todo - don't want user to have to pass in post code etc  */}
            {/*<PaymentElement options={{ fields: {billingDetails: "never" }}} />*/}
            <PaymentElement />
            <button type="submit" className="w-full mt-2 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500" disabled={!stripe || processing}>
                {processing ? 'Processing...' : 'Pay Now'}
            </button>
            {error && <div className="text-sm text-red-600">{error}</div>}
            {success && <div className="success">Payment Successful!</div>}
        </form>
    );
};

export default PaymentForm;
