import React, {useEffect} from "react";
import Header from "../components/Header";
import {useProducts} from '../providers/ProductContext'
import {useCart} from "../hooks/UseCart";
import {Link, useLocation} from "react-router-dom";
import Footer from "../components/Footer";

function OrderConfirmed() {
    const {products} = useProducts();
    const {clearCart} = useCart(products);

    useEffect(() => {
        clearCart();
    }, [clearCart]); // Empty dependency array ensures it runs only once



    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const orderNumber = queryParams.get('orderNumber');

    return (
        <div className="bg-white relative pt-20">
            <Header cart={[]}/>
            <div className="min-h-screen px-6 pt-8 lg:px-8">
                {orderNumber ? <div>
                    <p className={"text-2xl"}>Thanks for your order</p>
                    <p className={"py-4"}>Your order number is {orderNumber}</p>
                    <p className={"pb-4"}>You will receive an email confirming your order and expected delivery date shortly.</p>
                    <p>Head <Link to="/shop" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Back to Shop</Link> or feel free to <Link to="/contact" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Contact Us</Link> if there is anything we can help with</p>
                </div>
             : <div><p>There seems to have been a problem. </p><p className={"my-4"}>Head <Link to="/#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Back to Shop</Link> or feel free to <Link to="/" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Contact Us</Link> if there is anything we can help with</p></div>}
            </div>
            <Footer/>
        </div>
    );
}

export default OrderConfirmed;
