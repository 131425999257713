import {useState, useEffect} from 'react';

export const useCart = (products) => {

    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (productId) => {
        const product = products.find((item) => item.id === productId)
        const itemExists = cart.find((item) => item.id === productId);
        console.log(`will add product ${JSON.stringify(product)} to cart. ${itemExists?.quantity}`)
        if (itemExists) {
            if (itemExists.quantity < 15) {
                setCart(
                    cart.map((item) =>
                        item.id === product.id ? {...item, quantity: item.quantity + 1} : item
                    )
                );
            }
        } else {
            setCart([...cart, {id: product.id, quantity: 1}]);
        }
    };

    const removeFromCart = (productId) => {
        const product = products.find((item) => item.id === productId)
        const itemExists = cart.find((item) => item.id === productId && item.quantity > 0);

        if (itemExists) {
            setCart(
                cart.map((item) =>
                    item.id === product.id ? {...item, quantity: item.quantity - 1} : item
                ).filter((item) => item.quantity > 0)
        );
        }
    };

    const clearCart = () => {
        setCart([]);
    };

    return {cart, addToCart, removeFromCart, clearCart};
};

