import React, {useLayoutEffect} from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {useProducts} from '../../providers/ProductContext'
import {useCart} from '../../hooks/UseCart';

function About() {
    const {products} = useProducts();
    const {cart} = useCart(products);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>

            <section className="py-12 bg-white">
                <h2 className="text-3xl font-bold text-blue-900 text-center mb-6">About Us</h2>
                <div className="container mx-auto px-6 space-y-12 text-center">
                    <p className="text-gray-700 px-8">Designing and creating our patented system for the past 30 years, we won't sell a system unless it is the right solution for you.</p>
                </div>
            </section>

            <section className="bg-blue-50 py-16 px-6">
                <div
                    className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                    <div className="lg:w-1/2 text-center lg:text-left">
                        <h2 className="text-4xl font-bold text-blue-900 mb-8">Who are Dryhome Condensation?</h2>

                        <p className="text-lg text-gray-700 mb-6">We are a manufacturing company located in South Wales, dealing solely in condensation control. At our factory we manufacture the REMCON® Whole Home Ventilation Systems. We supply these units throughout the UK and offer a next day delivery service. We also offer a fitting service by fully qualified NICEC electricians throughout the South Wales area.</p>

                        <p className="text-lg text-gray-700 mb-10">For customers outside the South Wales area we are able to give contact details of local qualified electricians who will be able to fit these systems for you.
                        </p>

                    </div>

                    <div className="lg:w-1/2">
                        <img
                            src="./infographic.jpg"
                            alt="The REMCON"
                            className="w-full max-w-lg rounded-lg shadow-lg object-cover"
                        />
                    </div>

                </div>
            </section>

            <section className="bg-gray-100 py-16 px-6">
                <div
                    className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                    <div className="lg:w-1/2">
                        <img
                            // todo this one or the family one or something else??
                            src="./daisy-field.jpg"
                            alt="The REMCON"
                            className="w-full max-w-lg rounded-lg shadow-lg object-cover"
                        />
                    </div>

                    <div className="lg:w-1/2 text-center lg:text-left">
                        <h2 className="text-4xl font-bold text-blue-900 mb-8">Whole Home Ventilation Systems</h2>

                        <p className="text-lg text-gray-700 mb-6">These systems are the most effective method of condensation control for domestic properties. Take a look at our testimonials page and read comments from customers regarding the effectiveness of these systems and our level of customer service. Dryhome Condensation Limited really is the solution to your condensation problems.</p>

                        <p className="text-lg text-gray-700 mb-10">Our website has been developed to provide specific sections for our three main customer groups whose requirements and queries often differ slightly. So please have a look at the relevant section where we are sure you will find the answers to your questions about how and why Dryhome Condensation Ltd can help you with your condensation problems.</p>

                    </div>

                </div>
            </section>


            <Footer/>
        </div>
    );
}

export default About;
