import React, {useLayoutEffect} from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {useProducts} from '../../providers/ProductContext'
import {useCart} from '../../hooks/UseCart';

function Trade() {
    const {products} = useProducts();
    const {cart} = useCart(products);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>


            <section className="py-12 bg-white">
                <div className="container mx-auto px-10 pb-6 space-y-6">

                    <h2 className="text-3xl font-bold text-blue-900 text-center mb-6">Trade</h2>
                    <p className="text-l text-blue-900 text-center mb-6">How many times have you attended properties to
                        conduct a survey and found that the symptoms the customer’s property is exhibiting are in fact
                        caused by condensation rather than damp?</p>
                </div>
                <section className="bg-blue-50 py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left">
                            <h3 className="text-2xl font-bold text-blue-900 mb-4">Condensation
                                Solutions for Trade</h3>
                            <p className="text-gray-700 py-4">You lose valuable time on the survey which doesn’t produce
                                work for your company and the customer is unable to have their problem solved</p>

                            <p className="text-gray-700 py-4">At Dryhome Condensation we offer a simple solution which
                                allows you to give improved customer service. The REMCON whole home ventilation units
                                offer a competitively priced and effective solution to condensation problems. Many
                                damp-proofers across the U.K are taking advantage of our next day delivery mail order
                                service and the opportunity to fit these units in customer’s properties, For the
                                customer they provide an effective solution to condensation problems.</p>

                            <p className="text-gray-700 py-4">For you the damp-proofer, they allow you to provide your
                                customers with an additional service, whilst being simple and relatively quick to
                                install as well as being cheap to run. Hopefully the REMCON® unit will be a profitable
                                solution for yourselves on those surveys you carry out, when the so called ‘Damp
                                Problem’ turns out to be one of condensation.
                            </p>
                        </div>

                        <img src="./builder.jpg" alt="trade use"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>

                    </div>
                </section>

                <section className="bg-white py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row-reverse items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left ml-8">
                            <h3 className="text-2xl font-bold text-blue-900 mb-4">The new remote
                                Control REMCON</h3>
                            <p className="text-gray-700 py-4">The new remote control Remcon is our latest model,
                                specifically for properties with a loft space and fully complies with Part F Ventilation
                                2010 Building Regulations.</p>
                            <p className="text-gray-700 py-4">With over twenty years success with our whole home
                                ventilation systems, the only criticism from customers has been that in some smaller
                                properties too much air is inputted causing a slight draught. With the new Remcon
                                released in 2010, we have developed a system with 99 settings, permitting very fine
                                tuning to match the size of the property. With a range of 400 to 1400 revolutions per
                                minute the input can be adjusted to suit any property. The remote control system also
                                provides an excellent unique selling point, allowing adjustment without the use of
                                ladders or entering the loft space offering additional advantages for fitting in the
                                homes of the elderly or disabled.</p>
                            <p className="text-gray-700 py-4">The REMCON is a one piece unit as opposed to the units
                                provided by other suppliers. The REMCON is fitted in the attic space and all that is
                                visible in the living area is an unobtrusive white louvre on the landing ceiling. The
                                machine is very quiet in operation and offers a secure and safe way to ventilate your
                                home, without the need to leave windows open.</p>

                        </div>

                        <img src="./infographic.jpg" alt="REMCON infographic"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>

                    </div>
                </section>

                <section className="bg-gray-100 py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left">
                            <h3 className="text-2xl font-bold text-blue-900 mb-4">Simple Installation</h3>
                            <p className="text-gray-700 py-4">The REMCON units are simple to install. Installation time
                                for the REMCON is approximately 45 minutes. All units are supplied with comprehensive
                                fitting instructions. If you have any installation enquiries or would like more detailed
                                information, please contact us.
                            </p>


                        </div>

                        <img src="./remcon-unit-filter-remote.jpg" alt="REMCON components"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>

                    </div>
                </section>

            </section>


            <Footer/>
        </div>
    );
}

export default Trade;
