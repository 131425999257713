import React, {useLayoutEffect} from "react";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {useProducts} from '../../providers/ProductContext'
import {useCart} from '../../hooks/UseCart';

function Domestic() {
    const {products} = useProducts();
    const {cart} = useCart(products);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="bg-white relative pt-20 ">
            <Header cart={cart}/>

            <section className="py-12 bg-white">
                <div className="container mx-auto px-10 pb-6 space-y-6">

                <h2 className="text-3xl font-bold text-blue-900 text-center mb-6">Domestic</h2>
                <p className="text-l text-blue-900 text-center mb-6">REMCON® is whisper quiet, needs minimal
                    maintenance, has a proven
                    track
                    record and is guaranteed. It is a true ventilation system that uses nature's fresh air to
                    ventilate your entire house.</p>

                </div>
                <section className="bg-blue-50 py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left">
                            <h3 className="text-2xl font-bold text-blue-900 mb-4">Take control of Condensation</h3>
                            <p className="text-gray-700 py-4">
                                Windows dripping condensation, water pooling on sills, damp curtains, black mould
                                growth on walls and ceiling, mildew on clothing, musty smells....Welcome to a
                                typical UK home in Winter!</p>

                            <p className="text-gray-700 py-4">The REMCON® discreetly banishes condensation from
                                homes.</p>

                            <p className="text-gray-700 py-4">The system is installed in the roof space of the home
                                and the ceiling located louvre is unobtrusive enough to fit in with the decor of any
                                home.</p>
                        </div>

                        <div className="lg:w-1/2">
                            <div className="container mx-auto px-4 py-8">
                                <div className="grid grid-cols-2 gap-4">
                                    <img src="./window-condensation.jpg" alt="Mouldy Window 1"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                    <img src="./window-condensation-mould-hand.jpg" alt="Mouldy Window 2"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                    <img src="./window-mould.jpg" alt="Mouldy Window 3"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                    <img src="./condensation-mould-cleaner.jpg" alt="Mouldy Window 4"
                                         className="w-full h-full object-cover rounded-lg shadow-lg"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="bg-white py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row-reverse items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left ml-8">
                            <h3 className="text-2xl font-bold text-blue-900 mb-4">Peace of Mind</h3>
                            <p className="text-gray-700 py-4">The ventilation system is installed unobtrusively in
                                the roof space of your property, gently introducing fresh filtered air into the
                                home.</p>
                            <p className="text-gray-700 py-4">This fresh air replaces the moist, damp air created by
                                everyday living in the home. Unlike a conventional dehumidifier the REMCON® is
                                quiet, requires minimal maintenance, does not require daily emptying and is
                                concealed in the attic.</p>
                            <p className="text-gray-700 py-4">All you see is a slim unobtrusive louvre, not a large
                                piece of machinery that takes up floor space and has to be moved around.</p>
                            <p className="text-gray-700 py-4">The system ventilates the home from within, thereby
                                eliminating the need to open windows.</p>
                            <p className="text-gray-700 py-4">REMCON® customers can regulate the level of
                                ventilation required throughout the home via a new 99 speed infra-red remote
                                control.</p>
                            <p className="text-gray-700 py-4">These advances allow home owners to choose the desired
                                amount of ventilation required, thereby creating a cleaner, fresher home
                                environment.</p>

                        </div>

                        <img src="./family.jpg" alt="Domestic use"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>

                    </div>
                </section>

                <section className="bg-gray-100 py-16 px-6">
                    <div
                        className="container mx-auto max-w-6xl flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-10">

                        <div className="lg:w-1/2 text-center lg:text-left">
                            <div className="container mx-auto px-6 py-12 flex justify-center">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">

                                    <div className="flex items-start space-x-4">
                                        <div className="text-blue-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor"
                                                 stroke-width="4">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-blue-900">CONTROLS</h3>
                                            <p className="text-gray-700">Condensation, mildew, mould and
                                                dampness</p>
                                        </div>
                                    </div>

                                    <div className="flex items-start space-x-4">
                                        <div className="text-blue-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor"
                                                 stroke-width="4">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-blue-900">SAVES TIME</h3>
                                            <p className="text-gray-700">Banishes morning windowsill mopping up</p>
                                        </div>
                                    </div>

                                    <div className="flex items-start space-x-4">
                                        <div className="text-blue-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor"
                                                 stroke-width="4">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-blue-900">SECURITY</h3>
                                            <p className="text-gray-700">Gives curtains, carpets and paintwork a
                                                longer life</p>
                                        </div>
                                    </div>

                                    <div className="flex items-start space-x-4">
                                        <div className="text-blue-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor"
                                                 stroke-width="4">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-blue-900">HEALTH</h3>
                                            <p className="text-gray-700">Creates a fresher, cleaner, living
                                                environment for you and your family</p>
                                        </div>
                                    </div>

                                    <div className="flex items-start space-x-4">
                                        <div className="text-blue-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor"
                                                 stroke-width="4">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M5 13l4 4L19 7"/>
                                            </svg>
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-blue-900">ENERGY
                                                EFFICIENT</h3>
                                            <p className="text-gray-700">Low running costs, better utilisation of
                                                existing heating</p>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <img src="./in-situ-wide-angle.jpg" alt="A REMCON in place"
                             className="w-full md:w-1/2 rounded-lg shadow-md"/>

                    </div>
                </section>

            </section>

            <Footer/>
        </div>
    );
}

export default Domestic;
